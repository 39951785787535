const applications = ['login', 'userportal', 'account-settings', 'settings'];

export const betaApplications = [
  'branding',
  'conferences-admin',
  'screenpops-admin',
  'pivot',
  'ms-teams'
];

export const alphaApplications = ['webhooks'];

export default applications;
