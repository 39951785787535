import { KazooSDK } from '@commland/kazoo-js-sdk';
import Auth from './modules/auth';
import Branding from './modules/branding';
import Config from './modules/config';
import Events from './modules/events';
import HiddenFeatures from './modules/hidden-features';
import i18nModule from './modules/i18n';
import Libwebphone from './modules/libwebphone';
import Notifications from './modules/notifications';
import Routing from './modules/routing';
import SplashScreen from './modules/splash-screen';
import Themes from './modules/themes';
import Tray from './modules/tray-menu';
import Websockets from './modules/websockets';
import logger from './utils/logger';
import '@sipster/core/reset.scss';
import './assets/themes/themes.scss';
import './assets/main.scss';

// __APP_VERSION is configured and available only on build time
declare const __APP_VERSION__: string;

const isMobile = typeof window.commlandMobile !== 'undefined';

logger.info('Initializing application');
const platform = isMobile ? 'mobile' : 'web';
const auth = new Auth();
const branding = new Branding();
const hiddenFeatures = new HiddenFeatures();
const i18n = new i18nModule();
const notifications = new Notifications();
const routing = new Routing();
const splashScreen = new SplashScreen();
const themes = new Themes();
const websockets = new Websockets();

logger.debug('attaching commland modules to Window');

window.websockets = websockets;
window.commland = {
  auth,
  branding,
  hiddenFeatures,
  i18n,
  notifications,
  platform,
  routing,
  screenpops: { isAvailable: false },
  themes,
  version: __APP_VERSION__ ?? 'Unavailable'
};

logger.debug('initializing comm.land modules');
branding.init().then(async () => {
  themes.init();
  window.desktop?.common.notifyAppIsReady();
  splashScreen.start();
  Events.init();
  await Config.init();
  await KazooSDK.init();

  await i18n.init();
  auth.init();
  hiddenFeatures.init();
  await routing.init();
  websockets.init(window.config.WEBSOCKET_URL);

  /* TODO: add handler once push notifications are implemented 
  if (isMobile) {
    await Mobile.setup(async () => {
      // Force Libwebphone to start after push-notification registration
      await Libwebphone.init();
    });
  } else {
    await Libwebphone.init();
  } */

  await Libwebphone.init();
  Tray.init();
  splashScreen.stop();
});
