import { t } from 'i18next';

class CallForwarding {
  forwardingNumber: string | number | null;
  isEnabled: boolean;
  menu;

  constructor() {
    this.forwardingNumber = null;
    this.isEnabled = false;
    this.menu = [];

    this._updateMenu();
    this._initEventBindings();
  }

  getMenu() {
    return this.menu;
  }

  _updateMenu() {
    const show = !!this.forwardingNumber;
    const action = this.isEnabled ? 'disable' : 'enable';
    const actionLabel = t(`tray.callForwarding.${action}`, {
      number: this.forwardingNumber
    });

    if (!this.forwardingNumber) {
      this._resetMenu();
    } else {
      this.menu = [
        show
          ? {
              label: t('tray.callForwarding.title'),
              enabled: false
            }
          : undefined,
        show
          ? {
              label: actionLabel,
              click: 'tray-menu/call-forwarding.toggle'
            }
          : undefined,
        { type: 'separator' }
      ];
    }

    window.commlandEvents.emit('commland.tray.update');
  }

  _resetMenu() {
    this.menu = [];
  }

  _getToggleCallForwarding() {
    const module = this;
    return () => {
      const action = module.isEnabled ? 'disable' : 'enable';

      window.commlandEvents.emit(`commland.user.callForwarding.${action}`);
    };
  }

  _getOnUserCallForwardingUpdatedEvent() {
    const module = this;

    type CallForwardingPayload = {
      number: string | number | null;
      isEnabled: boolean;
    };

    return (payload: CallForwardingPayload) => {
      module.forwardingNumber = payload.number;
      module.isEnabled = payload.isEnabled;
      module._updateMenu();
    };
  }

  _initEventBindings() {
    window.commlandEvents.on(
      'commland.user.callForwarding.updated',
      this._getOnUserCallForwardingUpdatedEvent()
    );

    window.desktop.ipc.on(
      'tray-menu/call-forwarding.toggle',
      this._getToggleCallForwarding()
    );
  }
}

export default CallForwarding;
