import EventEmitter2 from 'eventemitter2';
import logger from '../utils/logger';

class Events {
  events: EventEmitter2;
  constructor() {
    this.events = new EventEmitter2({
      wildcard: true,
      maxListeners: 0
    });
  }

  init() {
    logger.info('events: Initializing events module');
    logger.info('events: Adding events module to window.commlandEvents');
    window.commlandEvents = this.events;
    window.commlandEvents.emit('commland.events.started');
  }
}

const events = new Events();
export default events;
