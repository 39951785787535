import { getAppStatus, registerApplication } from 'single-spa';
import {
  constructApplications as constructSingleSpaApplications,
  constructRoutes
} from 'single-spa-layout';
import { EXCLUDED_UI_SHELL_ROUTES } from './constants';
import { showExcept, showWhenPrefix } from '../../utils/routes';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import logger from '../../utils/logger';

export const constructRoutesConfig = (routes: string[]) => {
  return routes.map((name) => ({
    type: 'route',
    path: `#/${name}`,
    routes: [{ type: 'application', name }]
  }));
};

export const constructApplicationRoutes = (routes) => {
  return constructRoutes({
    mode: 'hash',
    base: '#',
    routes
  });
};

export const constructApplications = (routes) => {
  return constructSingleSpaApplications({
    routes,
    loadApp: ({ name }) => {
      return System.import(`@commland/${name}`);
    }
  });
};

export const registerApplications = (applications) => {
  applications.forEach((app) => {
    // skip already registered apps
    if (!getAppStatus(app.name)) {
      registerApplication({
        ...app,
        activeWhen: showWhenPrefix(['#/' + app.name])
      });
    }
  });
};

export const registerUIShell = (applications) => {
  applications.forEach((app) => {
    if (!getAppStatus(app.name)) {
      registerApplication({
        name: app.name,
        // @ts-expect-error gives ts error but actually works
        app: () => System.import(app.import),
        activeWhen: showExcept(EXCLUDED_UI_SHELL_ROUTES)
      });
    }
  });
};

export const registerWorkspaces = (applications) => {
  applications.forEach((app) => {
    if (!getAppStatus(app.name)) {
      registerApplication({
        ...app,
        activeWhen: showExcept(['#/login'])
      });
    }
  });
};

export const getImportUrlFromStorage = (app: string) => {
  try {
    return localStorage.getItem(`import-map-override:@commland/${app}`);
  } catch {
    return null;
  }
};

export const getIsChatWorkspaceEnabled = async () => {
  try {
    const {
      data: { data }
    } = await KazooSDK.streamChatStatus();

    return data?.enabled ?? false;
  } catch (error) {
    logger.error('Error retrieving chat module status', error);
    return false;
  }
};
