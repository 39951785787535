import { t } from 'i18next';

class Webphone {
  isOnline: boolean;
  status: 'online' | 'offline' | 'connecting';
  isVideoMuted: boolean;
  isScreensharing: boolean;
  menu;

  constructor() {
    this.isOnline = true;
    this.status = 'offline';
    this.isVideoMuted = true;
    this.isScreensharing = false;
    this.menu = [];

    this._initEventBindings();

    if (window.libwebphone.isWebphoneAvailable) {
      this._updateMenu();
    }
  }

  getMenu() {
    return this.menu;
  }

  _updateMenu() {
    const primaryCall = window.libwebphone.getCallList().getCall();
    const primaryCallHasSession = primaryCall && primaryCall.hasSession();
    const videoOptions = this._getVideoMenuOptions();
    const screenShareOptions = this._getScreenshareMenuOptions();
    const status = t(`tray.webphone.${this.status}`);
    const title = t('tray.webphone.title', {
      status
    });

    this.menu = [
      { label: title, enabled: false },
      {
        label: t('tray.webphone.open'),
        click: 'tray-menu/webphone.expand'
      },
      ...(primaryCallHasSession ? videoOptions : []),
      ...(primaryCallHasSession ? screenShareOptions : []),
      { type: 'separator' }
    ];

    window.commlandEvents.emit('commland.tray.update');
  }

  _getVideoMenuOptions() {
    const label = t(
      `tray.webphone.video.${this.isVideoMuted ? 'start' : 'stop'}`
    );

    return [
      {
        label,
        click: 'tray-menu/webphone.video'
      }
    ];
  }

  _getScreenshareMenuOptions() {
    const label = t(
      `tray.webphone.screenShare.${this.isScreensharing ? 'stop' : 'start'}`
    );

    return [
      {
        label,
        click: 'tray-menu/webphone.screenshare'
      }
    ];
  }

  _resetMenu() {
    this.menu = [];
    window.commlandEvents.emit('commland.tray.update');
  }

  _updateStatus() {
    const hasInternetConnection = this.isOnline;
    const isStarted = window.libwebphone.getUserAgent().isStarted();
    const isConnected = window.libwebphone.getUserAgent().isConnected();
    const isRegistered = window.libwebphone.getUserAgent().isRegistered();
    const isTryingToConnect = isStarted && isConnected && !isRegistered;
    const isOnline = isStarted && isConnected && isRegistered;

    if (!hasInternetConnection) {
      this.status = 'offline';
    } else if (isTryingToConnect) {
      this.status = 'connecting';
    } else if (isOnline) {
      this.status = 'online';
    } else {
      this.status = 'offline';
    }

    this._updateMenu();
  }

  _toggleIsVideoMuted(isMuted: boolean) {
    this.isVideoMuted = isMuted;
    this._updateMenu();
  }

  _toggleIsScreensharing(isScreensharing: boolean) {
    this.isScreensharing = isScreensharing;
    this._updateMenu();
  }

  _initEventBindings() {
    /**
     * Webphone events
     */

    const onUpdateEvent = () => {
      this._updateMenu();
    };

    window.libwebphone.on('callList.calls.added', onUpdateEvent);
    window.libwebphone.on('callList.calls.removed', onUpdateEvent);
    window.libwebphone.on('call.promoted', onUpdateEvent);
    window.libwebphone.on('call.established', onUpdateEvent);
    window.commlandEvents.on('commland.webphone.started', onUpdateEvent);

    window.commlandEvents.on('commland.webphone.stopped', () => {
      this._resetMenu();
    });

    // TODO: add event to documentation
    window.commlandEvents.on('commland.webphone.video.started', () => {
      this._toggleIsVideoMuted(false);
    });

    // TODO: add event to documentation
    window.commlandEvents.on('commland.webphone.video.stopped', () => {
      this._toggleIsVideoMuted(true);
    });

    // TODO: add event to documentation
    window.commlandEvents.on('commland.webphone.screenShare.started', () => {
      this._toggleIsScreensharing(true);
    });

    // TODO: add event to documentation
    window.commlandEvents.on('commland.webphone.screenShare.stopped', () => {
      this._toggleIsScreensharing(false);
    });

    /**
     * Status updates
     */

    window.addEventListener('online', () => {
      this._updateStatus();
    });

    window.addEventListener('offline', () => {
      this._updateStatus();
    });

    window.commlandEvents.on('commland.libwebphone.userAgent.started', () => {
      this._updateStatus();
    });

    window.commlandEvents.on('commland.libwebphone.userAgent.stopped', () => {
      this._updateStatus();
    });

    window.commlandEvents.on('commland.libwebphone.userAgent.connected', () => {
      this._updateStatus();
    });

    window.commlandEvents.on(
      'commland.libwebphone.userAgent.disconnected',
      () => {
        this._updateStatus();
      }
    );

    window.commlandEvents.on(
      'commland.libwebphone.userAgent.registration.registered',
      () => {
        this._updateStatus();
      }
    );

    window.commlandEvents.on(
      'commland.libwebphone.userAgent.registration.unregistered',
      () => {
        this._updateStatus();
      }
    );

    window.desktop.ipc.on('tray-menu/webphone.expand', () => {
      window.commlandEvents.emit('commland.webphone.expand');
    });

    window.desktop.ipc.on('tray-menu/webphone.video', () => {
      const action = this.isVideoMuted ? 'start' : 'stop';
      window.commlandEvents.emit(`commland.webphone.video.${action}`);
    });

    window.desktop.ipc.on('tray-menu/webphone.screenshare', () => {
      const action = this.isScreensharing ? 'stop' : 'start';
      window.commlandEvents.emit(`commland.webphone.screenShare.${action}`);
    });
  }
}

export default Webphone;
