import { t } from 'i18next';
import {
  chatWorkspaceIcon,
  conferencesWorkspaceIcon,
  contactsWorkspaceIcon,
  faxesWorkspaceIcon,
  phoneWorkspaceIcon
} from '../../../assets/workspaces';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import { getIsChatWorkspaceEnabled } from '../utils';

/**
 *  the version property is meant for the dock to know
 *  when a workspace document has been updated and if/when the url
 *  has changed, i.e. /userportal/conferences to /conferences-workspace
 */

const getWorkspaces = async () => {
  if (
    !KazooSDK.credentials ||
    !KazooSDK.credentials.account_name ||
    !KazooSDK.credentials.credentials
  ) {
    return [];
  }

  const isChatWorkspaceEnabled = await getIsChatWorkspaceEnabled();

  return [
    ...(isChatWorkspaceEnabled
      ? [
          {
            id: 'chat-workspace',
            name: 'chat-workspace',
            version: '2.5.0',
            icon: chatWorkspaceIcon,
            label: t('routing.workspaces.chat-workspace'),
            phase: 'beta',
            url: '/chat-workspace'
          }
        ]
      : []),
    {
      id: 'conferences-workspace',
      name: 'conferences-workspace',
      version: '2.4.0',
      label: t('routing.workspaces.conferences-workspace'),
      icon: conferencesWorkspaceIcon,
      phase: 'beta',
      url: '/conferences-workspace'
    },
    {
      id: 'contacts-workspace',
      name: 'contacts-workspace',
      version: '2.7.0',
      label: t('routing.workspaces.contacts-workspace'),
      icon: contactsWorkspaceIcon,
      url: '/contacts-workspace'
    },
    {
      id: 'faxes-workspace',
      name: 'faxes-workspace',
      version: '2.5.0',
      label: t('routing.workspaces.faxes-workspace'),
      icon: faxesWorkspaceIcon,
      url: '/faxes-workspace'
    },
    {
      id: 'phone-workspace',
      name: 'phone-workspace',
      version: '2.4.0',
      label: t('routing.workspaces.phone-workspace'),
      icon: phoneWorkspaceIcon
    },
    {
      id: 'screenpops',
      name: 'screenpops',
      version: '2.4.0',
      hidden: true
    },
    {
      id: 'external-websites',
      name: 'external-websites',
      version: '2.4.0',
      label: t('routing.workspaces.external-websites'),
      url: '/websites',
      hidden: true
    }
  ];
};

export default getWorkspaces;
