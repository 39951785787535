import i18next from 'i18next';
import logger from '../../utils/logger';
import en_US from './languages/en-US.json';

class i18n {
  i18n;

  async init() {
    logger.log('i18n: Initializing i18n module');
    this.i18n = await i18next.init({
      lng: 'en',
      supportedLngs: ['en'],
      fallbackLng: 'en',
      defaultNS: 'common',
      resources: {
        en: {
          common: en_US
        }
      }
    });
  }
}

export default i18n;
