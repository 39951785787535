import type { AppConfig } from '../types/globals';
import logger from '../utils/logger';

class Config {
  async init() {
    logger.info('config: Initializing config module');

    await this._fetchConfigFromFile();
  }

  async _fetchConfigFromFile() {
    let config: AppConfig = null;

    if (window.desktop) {
      config = await window.desktop.common.getConfig();
    } else {
      const isDev = window.location.host === 'localhost:9000';
      const configUrl = isDev
        ? 'http://localhost:9000/src/config.json'
        : './config.json';
      const response = await fetch(configUrl);
      config = await response.json();
    }

    // TODO: deprecate
    window.commioConfig = { ...config };
    window.config = { ...config };
    this._saveConfigInLocalStorage(config);
  }

  _saveConfigInLocalStorage(config: AppConfig) {
    window.localStorage.setItem(
      'webphone_websocket_url',
      config.WEBPHONE_WEBSOCKET_URL
    );
    window.localStorage.setItem('websocket_url', config.WEBSOCKET_URL);
    window.localStorage.setItem('api_url', config.API_URL);
    window.localStorage.setItem(
      'enable_webphone',
      config.ENABLE_WEBPHONE.valueOf.toString()
    );
    window.localStorage.setItem(
      'developer_tools',
      config.DEVELOPER_TOOLS.valueOf.toString()
    );
  }
}

const config = new Config();
export default config;
