import { t } from 'i18next';

type Device = {
  name: string;
  id: string;
  isDoNotDisturbEnabled: boolean;
};

class Devices {
  devices: Device[];
  menu;

  constructor() {
    this.devices = [];
    this.menu = [];

    this._updateMenu();
    this._initEventBindings();
  }

  getMenu() {
    return this.menu;
  }

  _updateMenu() {
    if (this.devices && this.devices.length) {
      this.menu = [
        { label: t('tray.devices.title'), submenu: this._buildDevices() },
        { type: 'separator' }
      ];
    } else {
      this._resetMenu();
    }

    window.commlandEvents.emit('commland.tray.update');
  }

  _buildDevices() {
    return this.devices.map(this._buildDevice);
  }

  _buildDevice(device: Device) {
    const { name, id, isDoNotDisturbEnabled } = device;
    const action = isDoNotDisturbEnabled ? 'disable' : 'enable';
    const deviceLabel = t(`tray.devices.${action}`, {
      device: name,
      interpolation: { escapeValue: false }
    });

    return {
      label: deviceLabel,
      click: {
        key: 'tray-menu/device.toggle-dnd',
        payload: { id, isDoNotDisturbEnabled }
      }
    };
  }

  _resetMenu() {
    this.menu = [];
    this.devices = [];
  }

  _initEventBindings() {
    window.commlandEvents.on(
      'commland.user.devices.dnd.updated',
      (payload: Device[]) => {
        this.devices = payload;
        this._updateMenu();
      }
    );

    type IPCEventPayload = { id: string; isDoNotDisturbEnabled: boolean };
    window.desktop.ipc.on(
      'tray-menu/device.toggle-dnd',
      (_, payload: IPCEventPayload) => {
        window.commlandEvents.emit(
          'commland.user.devices.dnd.toggle',
          payload.id,
          !payload.isDoNotDisturbEnabled
        );
      }
    );
  }
}

export default Devices;
