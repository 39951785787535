import type { KazooApplication } from './types';
import {
  getAppNames,
  navigateToUrl,
  start,
  unregisterApplication
} from 'single-spa';
import UI_SHELL_ROUTES from './defaults/ui-shell';
import getDefaultWorkspaces from './defaults/workspaces';
import DEFAULT_ROUTES from './defaults/applications';
import * as utils from './utils';
import { EXCLUDED_UI_SHELL_ROUTES } from './constants';
import logger from '../../utils/logger';
import { getPlatform } from '../../utils/mobile';
import getUserAppsList from './applications';

class Routing {
  baseUrl = '';
  workspaces: Record<string, any>[];
  workspaceNames: string[];
  // userApplications: KazooApplication[];
  // using Partial until backend improvement has finished
  // once backend improvement is finished remove all instances of Partial<KazooApplication>[]
  userApplications: Partial<KazooApplication>[];
  userApplicationNames: string[];

  async init() {
    logger.info('routing: starting routing module');

    if (window.desktop) {
      this.baseUrl = '.';
    } else if (getPlatform() === 'ios') {
      this.baseUrl = './localhost';
    }

    this._initEventBindings();

    await this.refreshRouting();

    start({ urlRerouteOnly: true });

    logger.info('routing: started routing module');
    window.commlandEvents.emit('commland.routing.started');
  }

  async refreshRouting() {
    logger.debug('routing: updating user workspaces list');
    this.workspaces = await getDefaultWorkspaces();
    this.workspaceNames = this.workspaces.map(({ id }) => id);

    logger.debug('routing: updating user application list');
    const userApplications = await this._getUserAvailableApplications();
    await this._loadApplications(userApplications);

    logger.debug('routing: updated available applications and workspaces');
    window.commlandEvents.emit('commland.routing.refreshed');
  }

  async refreshWorkspaces() {
    this.workspaces = await this._getUserAvailableWorkspaces();
    this.workspaceNames = this.workspaces.map(({ id }) => id);
    return this.workspaceNames;
  }

  getAvailableAppNames() {
    return getAppNames();
  }

  async removeUserApplications() {
    this.userApplicationNames.forEach((app) =>
      unregisterApplication(app).then(() => {
        System.delete(System.resolve(`@commland/${app}`));
      })
    );

    document.getElementById('commland-applications-importmap')?.remove();

    this.userApplications = [];
    this.userApplicationNames = [];
    this.workspaces = [];
    this.workspaceNames = [];
    await this.refreshRouting();
    logger.info('routing: removed user applications');
  }

  navigate(url: string) {
    if (window.desktop) {
      window.commlandEvents.emit('commland.websites.hideAll');
    }

    navigateToUrl(`#${url}`);
  }

  async _unregisterUIShell() {
    UI_SHELL_ROUTES.forEach((i) => {
      unregisterApplication(i).then(() => {
        System.delete(System.resolve(`@commland/${i}`));
      });
    });

    logger.info('routing: removed UI shell');
  }

  async _loadApplications(apps: Partial<KazooApplication>[]) {
    const uiShell = this._getUIShell();
    const workspaces = this._getWorkspaces();
    const applications = this._getApplications(apps);
    const mergedImports = [...uiShell, ...workspaces, ...applications];

    this.userApplications = apps;
    this.userApplicationNames = apps.map((app) => app.name);

    this._loadImportMap(mergedImports);
    utils.registerUIShell(uiShell);
    utils.registerWorkspaces(workspaces);
    utils.registerApplications(applications);
  }

  async _loadImportMap(applications) {
    const im = document.createElement('script');
    const imports = {};
    im.id = 'commland-applications-importmap';
    im.type = 'systemjs-importmap';

    const disabledOverridesString = localStorage.getItem(
      'import-map-overrides-disabled'
    );
    const disabledOverrides = JSON.parse(disabledOverridesString ?? '[]');

    applications.forEach(({ name }) => {
      const appName = `@commland/${name}`;
      const urlFromStorage = utils.getImportUrlFromStorage(name);
      const isOverrideDisabled = disabledOverrides.find(
        (item) => item === appName
      );

      const url =
        (!isOverrideDisabled && urlFromStorage) ||
        `${this.baseUrl}/applications/${name}/commland-${name}.js`;

      imports[appName] = url;
    });

    im.textContent = JSON.stringify({
      imports
    });

    document.getElementById('commland-applications-importmap')?.remove();
    document.head.appendChild(im);
    logger.info('routing: loaded applications importmap');
  }

  _getWorkspaces() {
    const routeConfig = utils.constructRoutesConfig(this.workspaceNames);
    const routes = utils.constructApplicationRoutes(routeConfig);
    return utils.constructApplications(routes);
  }

  _getUIShell() {
    const uiShell = this._getUserAvailableUIShell();
    return uiShell.map((name) => ({
      name,
      import: `@commland/${name}`,
      excludedRoutes: EXCLUDED_UI_SHELL_ROUTES
    }));
  }

  _getApplications(userApplications: Partial<KazooApplication>[]) {
    const userApplicationsNames = userApplications.map((a) => a.name);
    const mergedApplications = [...DEFAULT_ROUTES, ...userApplicationsNames];
    const routeConfig = utils.constructRoutesConfig(mergedApplications);
    const routes = utils.constructApplicationRoutes(routeConfig);

    return utils.constructApplications(routes);
  }

  _getUserAvailableWorkspaces() {
    if (!this._isUserAuthenticated()) {
      return [];
    }
    return getDefaultWorkspaces();
  }

  _getUserAvailableUIShell() {
    if (!this._isUserAuthenticated()) {
      return [];
    }

    return UI_SHELL_ROUTES;
  }

  // if user is authorized it will return the list of available applications for the user
  // if not, it will return an empty array
  async _getUserAvailableApplications() {
    if (!this._isUserAuthenticated()) {
      return [];
    }

    const userApplications = await getUserAppsList();

    return userApplications as Partial<KazooApplication>[];
  }

  _isUserAuthenticated() {
    return window.commland.auth.isUserAuthenticated();
  }

  // if user is not authenticated redirect to /login
  // if user is authenticated but target url does not exist or
  //  user does not have access to the application redirect to /phone-workspace
  _onUserNavigationEvent(event: CustomEvent) {
    event.preventDefault();
    const targetUrl = event.detail.newUrl as string;
    const isAuthenticated = this._isUserAuthenticated();

    if (!isAuthenticated) {
      logger.debug('routing: no auth token found, redirecting to /login');
      navigateToUrl('#/login');
      return;
    }

    const urlObject = new URL(targetUrl);
    const { hash } = urlObject;
    const targetPathname = hash.slice(2);
    const targetAppName = targetPathname.split('/')[0];
    const isAppAvailable = this.getAvailableAppNames().find(
      (app) => app === targetAppName
    );

    // External websites don't have a route, so we prevent
    // redirecting when navigating to them in dock
    if (targetAppName === 'websites') {
      return;
    }

    if (!isAppAvailable) {
      logger.info(
        `routing: route not found or user does not have access to application ${targetAppName}, redirecting to /phone-workspace`
      );
      // use cancelNavigation() instead https://single-spa.js.org/docs/api/#canceling-navigation
      navigateToUrl('#/phone-workspace');
      return;
    }

    if (targetAppName === 'login') {
      logger.debug(
        `routing: user tried to access /login while authenticated, redirecting to /phone-workspace`
      );
      // use cancelNavigation() instead https://single-spa.js.org/docs/api/#canceling-navigation
      navigateToUrl('#/phone-workspace');
      return;
    }
  }

  _initEventBindings() {
    window.commlandEvents.on('commland.routing.refresh', () =>
      this.refreshRouting()
    );

    window.addEventListener(
      'single-spa:before-app-change',
      (event: CustomEvent) => this._onUserNavigationEvent(event)
    );
  }
}

export default Routing;
