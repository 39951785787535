import { v4 as uuid } from 'uuid';
import logger from '../utils/logger';
import { KazooSDK } from '@commland/kazoo-js-sdk';

type Listener = (data: unknown) => void;
type ListenersMap = Record<any, any>;

class Websockets {
  _url: string;
  _websocket: WebSocket;
  _messageListeners: ListenersMap;
  _serverPingInterval: ReturnType<typeof setInterval>;
  _reconnectDepth: number;
  _isReconnecting: boolean;

  constructor() {
    this._messageListeners = new Map() as unknown as ListenersMap;
    this._reconnectDepth = 0;
    this._isReconnecting = false;
  }

  init(url: string) {
    logger.info('websockets: Initializing websocket module');
    this._url = url;
    this._startWebsocket();
  }

  subscribe(binding: string, listener: Listener) {
    const currentBindings = this._messageListeners.get(binding);
    const listenerToAdd = (event: string) => {
      listener(event);
    };

    if (currentBindings && currentBindings.size) {
      // commenting out since it increases the log size and is not useful atm
      // logger.debug('websockets: Adding listener to binding', binding);
      this._messageListeners.set(binding, currentBindings.add(listenerToAdd));
      window.commlandEvents.emit('commland.websockets.subscribed', { binding });
    } else {
      // commenting out since it increases the log size and is not useful atm
      // logger.debug('websockets: Creating subscription to binding', binding);
      this._messageListeners.set(binding, new Set([listenerToAdd]));

      if (this._websocket && this._websocket.readyState === 1) {
        const subscriptionMessage = this._createSubscriptionMessage(
          binding,
          'subscribe'
        );

        this.sendMessage(subscriptionMessage);
        window.commlandEvents.emit('commland.websockets.subscribed', {
          binding
        });
      }
    }

    return () => {
      const currentBindings = this._messageListeners.get(binding);
      currentBindings.delete(listenerToAdd);

      if (!currentBindings || currentBindings.size === 0) {
        logger.debug(
          'websockets: There are no listeners for binding, unsubscribing from wss binding',
          binding
        );

        this._messageListeners.delete(binding);
        const unsubscribeMessage = this._createSubscriptionMessage(
          binding,
          'unsubscribe'
        );

        this.sendMessage(unsubscribeMessage);
      }
    };
  }

  sendMessage(message: string) {
    if (this._websocket.readyState !== WebSocket.OPEN) return;
    this._websocket.send(message);
  }

  close() {
    if (this._serverPingInterval) {
      clearInterval(this._serverPingInterval);
      this._serverPingInterval = null;
    }

    if (this._websocket) {
      this._websocket.close();
      window.commlandEvents.emit('commland.websockets.stopped');
    }
  }

  _startWebsocket() {
    this._websocket = new WebSocket(this._url);
    this._websocket.onmessage = this._getOnWebsocketMessage();
    this._websocket.onopen = this._getOnWebsocketOpen();
    this._websocket.onclose = this._getOnWebsocketClose();
    this._websocket.onerror = this._getOnWebsocketError();
    window.commlandEvents.emit('commland.websockets.started');
  }

  _reconnectWebsocket() {
    window.commlandEvents.emit('commland.websockets.reconnecting');
    this._startWebsocket();
  }

  _getOnWebsocketOpen() {
    const module = this;

    return () => {
      logger.info('websockets: Opened websocket connection');

      module._messageListeners.forEach((_, key) => {
        const subscriptionMessage = module._createSubscriptionMessage(
          key,
          'subscribe'
        );

        module._websocket.send(subscriptionMessage);
      });

      module._createServerPingInterval();
      window.commlandEvents.emit('commland.websockets.opened');
      if (!module._isReconnecting) return;

      module._isReconnecting = false;
      module._reconnectDepth = 0;
      window.commlandEvents.emit('commland.websockets.reconnected');
    };
  }

  _getOnWebsocketMessage() {
    const module = this;
    return (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.subscribed_key) {
          const listeners = module._messageListeners.get(data.subscribed_key);

          if (listeners.size) {
            listeners.forEach((listener) => listener(data));
          }
        }
      } catch (error) {
        logger.error('websockets: Error while parsing websocket event', {
          event,
          error
        });
      }
    };
  }

  _getOnWebsocketClose() {
    return (event) => {
      logger.info('websockets: Closed websocket connection', {
        code: event.code
      });
    };
  }

  _getOnWebsocketError() {
    const module = this;
    return (event) => {
      logger.info('websockets: Error on websocket connection', event);

      if (!module._isReconnecting) {
        module._websocket.close();
      }
    };
  }

  _createServerPingInterval() {
    const module = this;
    logger.info('websockets: creating ping interval');

    this._serverPingInterval = setInterval(() => {
      module._websocket && module._ping();
    }, 60 * 1000);
  }

  _ping() {
    const auth_token = KazooSDK.getAuthToken();

    const ping = {
      action: 'ping',
      auth_token,
      requestId: uuid()
    };

    const message = JSON.stringify(ping);

    try {
      this.sendMessage(message);
    } catch (error) {
      logger.error('websockets: error sending ping event');
    }
  }

  // TODO: use new kazoo-js-sdk instead of localStorage
  _createSubscriptionMessage = (
    binding: string,
    action: 'subscribe' | 'unsubscribe'
  ) => {
    const auth_token = KazooSDK.getAuthToken();
    const account_id = KazooSDK.getUserAccountId();
    const subscriptionMessage = {
      action,
      auth_token,
      requestId: uuid(),
      data: {
        binding,
        account_id
      }
    };

    return JSON.stringify(subscriptionMessage);
  };
}

export default Websockets;
