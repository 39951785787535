import { v4 as uuid } from 'uuid';
import logger from '../utils/logger';

type NotificationID = string;

type CustomAdditionalInformation = Record<string, string>;
type AdditionalInformation = CustomAdditionalInformation & {
  id: string;
  title: string;
  description: string;
  type: string;
};

interface Notification {
  id: NotificationID;
  additionalInformation?: AdditionalInformation;
}

type ApplicationID = string;
type NotificationsMap = Record<ApplicationID, Array<Notification>>;

class Notifications {
  _notifications: NotificationsMap;

  constructor() {
    logger.info('notifications: Initializing notifications module ');
    this._notifications = {};
  }

  getNotifications(): NotificationsMap;
  getNotifications(applicationId: ApplicationID): Notification[];
  getNotifications(applicationId = null) {
    if (applicationId) {
      if (!this._notifications[applicationId]) {
        this._addApplicationToNotifications(applicationId);
      }

      return this._notifications[applicationId];
    }

    return this._notifications;
  }

  add(
    applicationId: ApplicationID,
    additionalInformation: AdditionalInformation = null
  ) {
    const id = (additionalInformation && additionalInformation.id) || uuid();
    const notification: Notification = { id, additionalInformation };
    const applicationNotifications = this._notifications[applicationId];

    if (!applicationNotifications) {
      this._addApplicationToNotifications(applicationId);
    }

    this._notifications[applicationId].push(notification);

    window.commlandEvents.emit('commland.notifications.refreshed');
    window.commlandEvents.emit(
      `commland.notifications.${applicationId}.added`,
      notification
    );

    return id;
  }

  remove(applicationId: ApplicationID, notificationId: NotificationID = null) {
    const applicationNotifications = this.getNotifications(applicationId);

    if (notificationId) {
      const newNotifications = applicationNotifications.filter(
        (n) => n.id !== notificationId
      );

      this._notifications[applicationId] = newNotifications;
    } else {
      this._notifications[applicationId].shift();
    }

    window.commlandEvents.emit('commland.notifications.refreshed');
    window.commlandEvents.emit(
      `commland.notifications.${applicationId}.removed`,
      notificationId
    );
  }

  clear(applicationId: ApplicationID = null, amount: number = null) {
    if (!applicationId) return;

    if (amount) {
      this._notifications[applicationId].splice(0, amount);
    } else if (amount === null) {
      this._notifications[applicationId] = [];
    }

    window.commlandEvents.emit('commland.notifications.refreshed');
    window.commlandEvents.emit(
      `commland.notifications.${applicationId}.cleared`
    );
  }

  clearAll() {
    logger.info('notifications: Removing notifications for all applications');
    Object.keys(this._notifications).forEach((app) => {
      this._notifications[app] = [];
    });

    window.commlandEvents.emit('commland.notifications.refreshed');
    window.commlandEvents.emit('commland.notifications.all.cleared');
  }

  _addApplicationToNotifications(applicationId: ApplicationID) {
    if (this._notifications[applicationId]) {
      return;
    }

    this._notifications[applicationId] = [];
  }
}

export default Notifications;
