import { t } from 'i18next';
import { navigateToUrl } from 'single-spa';
import WebphoneSection from './sections/webphone';
import CallForwardingSection from './sections/call-forwarding';
import DevicesSection from './sections/devices';
import logger from '../../utils/logger';

class TrayMenu {
  isLoggedIn: boolean;
  appName: string;
  titleSection;
  faxesSection;
  webphoneSection;
  callForwardingSection;
  devicesState;
  devicesSection;
  shortcutsSection;

  constructor() {
    this.isLoggedIn = false;
    this.titleSection = [];
    this.faxesSection = [];
    this.callForwardingSection = [];
  }

  async init() {
    if (!window.desktop?.tray) return;
    logger.info('tray: Initializing desktop tray menu');

    this.appName = await window.desktop.branding.getAppName();
    this.webphoneSection = new WebphoneSection();
    this.callForwardingSection = new CallForwardingSection();
    this.devicesSection = new DevicesSection();
    this._initEventBindings();
    await this._getTitleMenuSection();
    this._getFaxesMenuSection();
    this._getShortcutsMenuSection();
    this._updateTrayMenu();

    window.commlandEvents.emit('commland.tray.started');
  }

  focusMainWindow() {
    window.desktop.common.focusMainWindow();
  }

  async _updateTrayMenu() {
    const menu = this.isLoggedIn
      ? this._getLoggedInMenu()
      : await this._getLoggedOutMenu();

    try {
      window.desktop.tray.updateMenu(menu.filter((item) => !!item));
      window.commlandEvents.emit('commland.tray.updated');
    } catch (error) {
      logger.error('tray: There was an error updating the Tray Menu', error);
    }
  }

  _getLoggedInMenu() {
    const webphone = this.webphoneSection.getMenu();
    const callForwarding = this.callForwardingSection.getMenu();
    const devices = this.devicesSection.getMenu();

    return [
      ...this.titleSection,
      ...this.faxesSection,
      ...webphone,
      ...callForwarding,
      ...devices,
      ...this.shortcutsSection
    ];
  }

  async _getLoggedOutMenu() {
    const isDev = window.location.host === 'localhost:9000';

    const title = isDev ? `${this.appName} [development]` : this.appName;

    return [
      { label: title, enabled: false },
      {
        label: t('tray.show', { app_name: this.appName }),
        click: 'tray-menu/focus-main-window'
      },
      {
        label: t('tray.quit', { app_name: this.appName }),
        click: 'tray-menu/quit-application'
      }
    ];
  }

  async _getTitleMenuSection() {
    const isDev = window.location.host === 'localhost:9000';
    const appName = await window.desktop.branding.getAppName();
    const title = isDev ? `${appName} [development]` : appName;

    this.titleSection = [
      { label: title, enabled: false },
      {
        label: t('tray.show', { app_name: this.appName }),
        click: 'tray-menu/focus-main-window'
      },
      { type: 'separator' }
    ];
  }

  _getFaxesMenuSection() {
    this.faxesSection = [
      {
        label: t('tray.faxes'),
        click: 'tray-menu/open-faxes'
      },
      { type: 'separator' }
    ];
  }

  _getShortcutsMenuSection() {
    this.shortcutsSection = [
      {
        label: t('tray.preferences'),
        click: 'tray-menu/open-settings'
      },
      {
        label: t('tray.logout'),
        click: 'tray-menu/log-out'
      },
      {
        label: t('tray.quit', { app_name: this.appName }),
        click: 'tray-menu/quit-application'
      }
    ];
  }

  _initEventBindings() {
    window.commlandEvents.on('commland.tray.update', () =>
      this._updateTrayMenu()
    );

    window.addEventListener('single-spa:app-change', (event: CustomEvent) => {
      const changes = event.detail;
      if (changes.newAppStatuses) {
        const isInLogInPage = changes.newAppStatuses.login === 'MOUNTED';

        this.isLoggedIn = !isInLogInPage;
        this._updateTrayMenu();
      }
    });

    window.desktop.ipc.on('tray-menu/focus-main-window', this.focusMainWindow);

    window.desktop.ipc.on(
      'tray-menu/quit-application',
      window.desktop.common.quit
    );

    window.desktop.ipc.on('tray-menu/open-faxes', () => {
      navigateToUrl('#/faxes-workspace');

      this.focusMainWindow();
    });

    window.desktop.ipc.on('tray-menu/open-settings', () => {
      navigateToUrl('#/settings');
      this.focusMainWindow();
    });

    window.desktop.ipc.on('tray-menu/log-out', () => {
      window.commlandEvents.emit('commland.app.logout');
      this.focusMainWindow();
    });
  }
}

const tray = new TrayMenu();
export default tray;
