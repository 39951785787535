import libwebphone from 'libwebphone';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import type { LibwebphoneConfiguration } from '../types/libwebphone';
import logger from '../utils/logger';
import {
  addMobileSetupToLibwebphoneConfiguration,
  getIsNativePlatform,
  getPlatform
} from '../utils/mobile';
import { OutputDeviceType } from '../types/iosrtc';
import { TEL_PROTOCOL_NAME } from '../utils/constants';

class Libwebphone extends libwebphone {
  isWebphoneAvailable = false;
  webphone_device_id: string | null;
  _userAgentRefreshTimeoutID: number;

  constructor(config) {
    super(config);
    logger.info('libwebphone: initializing libwebphone module');
    this._initEventBindings();
    this._initCommlandEventBindings();
    window.desktop && this._initDesktopEventsBindings();
    this._getStartArguments();
  }

  stop() {
    logger.info('libwebphone: stopping libwebphone');
    this.getUserAgent().stop();
  }

  async startWebphone(device_id: string) {
    logger.info('libwebphone: Checking webphone availability');
    // TODO: leaving as a reference
    // not needed because check is being done in commland-phone-workspace,
    // but we still should consider moving this check to this file?
    // const isUserAuthenticated = window.commland.auth.isUserAuthenticated();
    // const isAvailable = await this._getIsWebphoneAvailableForUser()
    // logger.info('libwebphone: Webphone is available');

    logger.info('libwebphone: starting libwebphone user agent');
    this.webphone_device_id = device_id;
    await this._refreshUserAgent();
  }

  async _getStartArguments() {
    if (window.desktop) {
      const startArguments = await window.desktop.common.getStartArguments();
      if (
        startArguments &&
        startArguments.protocol &&
        startArguments.protocol === TEL_PROTOCOL_NAME
      ) {
        this.getDialpad()._target = startArguments.argument.split('');
      }
    }
  }

  async _refreshUserAgent() {
    logger.debug('libwebphone: refreshing user agent');
    let token: string = null;
    let realm: string = null;
    let username: string = null;

    try {
      const {
        token: ephemeralToken,
        ua: { Realm, Username }
      } = await window.commland.auth.getDeviceEphemeralToken(
        this.webphone_device_id
      );

      token = ephemeralToken;
      realm = Realm;
      username = Username;
    } catch (error) {
      token = null;
      realm = null;
      username = null;
    }

    if (!token || !realm || !username) {
      logger.info('libwebphone: No auth token available for webphone');
      return;
    }

    window.libwebphone.getUserAgent()._config.authentication.jwt = token;

    window.libwebphone.getUserAgent()._config.user_agent.contact_uri = `sip:${username}@${realm}`;

    window.libwebphone.getUserAgent().start(username, token, realm);
  }

  async _getIsWebphoneAvailableForUser() {
    try {
      const device = await KazooSDK.getUserDefaultWebphoneDevice();
      if (!device || !device.id) return false;
      return true;
    } catch (error) {
      return false;
    }
  }

  _initEventBindings() {
    this.onAny(this._reEmitLibWebphoneEvents);

    this.on('userAgent.started', () => {
      this.isWebphoneAvailable = true;
    });

    this.on('userAgent.stopped', () => {
      this.isWebphoneAvailable = false;
    });

    this.on('userAgent.registration.failed', () => {
      this._refreshUserAgent();
    });

    this.on('mediaDevices.devices.loaded', () => {
      const storedVideoInput = localStorage.getItem(
        'commland-webphone:selected-videoinput-device'
      );

      if (storedVideoInput) {
        this.getMediaDevices().changeDevice('videoinput', storedVideoInput);
      }
    });
  }

  _initCommlandEventBindings() {
    window.commlandEvents.on(
      'commland.webphone.started',
      (device_id: string) => {
        this.startWebphone(device_id);
      }
    );
  }

  _initDesktopEventsBindings() {
    window.desktop.ipc.on(
      'commland.webphone.dial',
      (_, phoneNumber: string) => {
        if (!window.commland.auth.isUserAuthenticated()) return;
        this.getDialpad()._target = phoneNumber.split('');
        this.emit('dialpad.target.updated');
        window.commlandEvents.emit('commland.webphone.focus');
      }
    );
  }

  _reEmitLibWebphoneEvents(event: string, ...args: any) {
    window.commlandEvents.emit(`commland.libwebphone.${event}`, ...args);
  }
}

async function getDefaultConfig(): Promise<LibwebphoneConfiguration> {
  const appName = window.commland.branding.app_name ?? 'comm.land';
  const version = window.desktop
    ? await window.desktop.common.getVersion()
    : window.commland.version;

  const user_agent = `${appName} - ${version}`;
  const websocketUrl = window.config.WEBPHONE_WEBSOCKET_URL;

  const audioinput = {
    enabled: true,
    preferedDeviceIds: []
  };
  const audiooutput = {
    preferedDeviceIds: []
  };
  const ringoutput = {
    preferedDeviceIds: []
  };

  const storedAudioInput = localStorage.getItem(
    'commland-webphone:selected-audioinput-device'
  );
  const storedAudioOutput = localStorage.getItem(
    'commland-webphone:selected-audiooutput-device'
  );
  const storedRingOutput = localStorage.getItem(
    'commland-webphone:selected-ringoutput-device'
  );

  if (storedAudioInput) {
    audioinput.preferedDeviceIds = [storedAudioInput];
  }

  if (storedAudioOutput) {
    audiooutput.preferedDeviceIds = [storedAudioOutput];
  }

  if (storedRingOutput) {
    ringoutput.preferedDeviceIds = [storedRingOutput];
  }

  const defaultConfig: LibwebphoneConfiguration = {
    dialpad: {
      renderTargets: [],
      globalKeyShortcuts: false,
      keys: {
        enter: { enabled: false },
        escape: { enabled: false },
        backspace: { enabled: false }
      }
    },
    callList: { renderTargets: [] },
    callControl: { renderTargets: [] },
    call: {
      startWithAudioMuted: false,
      startWithVideoMuted: true
    },
    mediaDevices: {
      renderTargets: [],
      audioinput,
      audiooutput,
      ringoutput
    },
    audioContext: { renderTargets: [] },
    videoCanvas: { enabled: false },
    userAgent: {
      renderTargets: [],
      user_agent: { user_agent },
      transport: {
        sockets: [websocketUrl],
        recovery_max_interval: 30,
        recovery_min_interval: 2
      }
    }
  };

  if (getIsNativePlatform()) {
    return addMobileSetupToLibwebphoneConfiguration(defaultConfig);
  }

  return defaultConfig;
}

async function init() {
  const config = await getDefaultConfig();

  const platform = getPlatform();

  /*
     This patches some WebRTC and Media Devices methods
     using cordova-plugin-iosrtc.
  */
  if (platform === 'ios') {
    window.cordova?.plugins.iosrtc?.registerGlobals();
    const storedAudioOutput = localStorage.getItem(
      'commland-webphone:selected-audiooutput-device'
    );
    if (storedAudioOutput) {
      // set the stored device value as default for all calls
      window.cordova?.plugins.iosrtc?.setDefaultAudioOutput(
        storedAudioOutput as OutputDeviceType
      );
    }
  }

  window.libwebphone = new Libwebphone(config);
}

export default {
  init
};
