import React from 'react';
import { Root, createRoot } from 'react-dom/client';
import { t } from 'i18next';
import { SplashScreen as SplashScreenComponent } from '@commland/components';
import logger from '../utils/logger';

class SplashScreen {
  _MAX_DELAY_MS = 1000;
  _progress = 0;
  _startTime: number;
  _element: HTMLDivElement;
  _progressInterval: number;
  _root: Root;

  constructor() {
    const element = document.createElement('div');
    element.id = 'splash_screen';
    this._element = element;
    document.body.appendChild(element);
  }

  start() {
    logger.info('splash-screen: Starting splash screen');
    this._renderComponent(0);
    this._startTime = Date.now();
    this._createProgressUpdateJob();
  }

  async stop() {
    logger.info('splash-screen: stopping splash screen');

    const currentTime = Date.now();
    const difference = currentTime - this._startTime;
    if (difference < this._MAX_DELAY_MS) {
      await this._sleep(this._MAX_DELAY_MS - difference);
    }

    if (this._progressInterval) clearInterval(this._progressInterval);
    if (this._progress < 100) {
      this._renderComponent(100);
      // this gives time to React to re-render the progress bar to 100% and the user to see it complete
      await this._sleep(100);
    }

    this._unmountComponent();
  }

  _createProgressUpdateJob() {
    this._progressInterval = setInterval(() => {
      this._progress =
        this._progress + Math.floor(Math.random() * (10 - 1 + 1) + 1);

      if (this._progress > 100) this._progress = 100;
      this._renderComponent(this._progress);
    }, 250) as unknown as number;
  }

  async _renderComponent(progress = 0) {
    const component = React.createElement(
      SplashScreenComponent,
      { label: t('common.splashScreen'), progress },
      null
    );

    if (!this._root) {
      this._root = createRoot(this._element);
    }

    this._root.render(component);
  }

  _unmountComponent() {
    this._element.remove();
    this._element = null;
  }

  async _sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export default SplashScreen;
