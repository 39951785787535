const logger = window.desktop?.logger || console;

const wrapper = {
  log: (message: string, ...args) =>
    logger.log(`[commland-core] ${message}`, ...args),
  warn: (message: string, ...args) =>
    logger.warn(`[commland-core] ${message}`, ...args),
  error: (message: string, ...args) =>
    logger.error(`[commland-core] ${message}`, ...args),
  debug: (message: string, ...args) =>
    logger.debug(`[commland-core] ${message}`, ...args),
  info: (message: string, ...args) =>
    logger.info(`[commland-core] ${message}`, ...args)
};

export default wrapper;
