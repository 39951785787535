import logger from '../utils/logger';

export type Whitelabel = {
  custom_welcome: boolean;
  custom_welcome_message: string;
  commland: {
    theme: {
      primary_color: string;
    };
  };
};

const OBJECT_STORE = 'branding';

class Branding {
  whitelabel: Whitelabel | null;
  company_logo: string | null; // base64
  app_icon: string | null; // base64
  app_name: string | null;
  _db: IDBDatabase | null;

  constructor() {
    logger.info('branding: Starting branding module');
    this.whitelabel = null;
    this.company_logo = null;
    this.app_icon = null;
  }

  async init() {
    await this._initAppName();
    await this._initWhitelabelInformation();
    await this._initBrandingDB();
    await this._initAppIcon();
  }

  async saveWhitelabelInformation(whitelabel: Whitelabel) {
    logger.info('branding: Persisting whitelabel information');
    window.localStorage.setItem('whitelabel', JSON.stringify(whitelabel));
    this.whitelabel = whitelabel;
  }

  // stores the company logo in and persists it in indexedDB
  async saveCompanyLogo(logo: string) {
    this.company_logo = logo;

    new Promise((resolve, reject) => {
      logger.info('branding: Persisting company logo');
      const transaction = this._db.transaction(OBJECT_STORE, 'readwrite');
      const addRequest = transaction
        .objectStore(OBJECT_STORE)
        .put(logo, 'company_logo');

      addRequest.onerror = () => {
        reject(Error('Could not save company_logo'));
      };

      transaction.oncomplete = () => {
        logger.info('branding: Successfully stored company logo');
        resolve(true);
      };
    });
  }

  async _initCompanyLogo() {
    const storedCompanyLogo = await this._getStoredCompanyLogo();

    if (storedCompanyLogo) {
      this.company_logo = storedCompanyLogo;
      return;
    }

    if (!window.desktop) return;
    logger.info(
      'branding: Company logo does not exist in DB, using logo from branding folder'
    );

    const companyLogo = await window.desktop.branding.getCompanyLogoBase64();
    if (companyLogo) {
      this.company_logo = companyLogo;
      this.saveCompanyLogo(companyLogo);
    }
  }

  async _getStoredCompanyLogo(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const transaction = this._db.transaction(OBJECT_STORE, 'readwrite');
      const request = transaction.objectStore(OBJECT_STORE).get('company_logo');

      request.onerror = () => {
        reject(null);
      };

      request.onsuccess = (e: any) => {
        const company_logo = e.target.result as string;
        resolve(company_logo || null);
      };
    });
  }

  async _initAppName() {
    logger.info('branding: Initializing application name');
    // TODO: apply branding css on web
    // when applying css branding on web perhaps add directly to index.ejs
    if (window.desktop) {
      logger.info('branding: fetching application name from local file');
      const appName = await window.desktop.branding.getAppName();
      this.app_name = appName;
      document.title = appName;
    }
  }

  async _initWhitelabelInformation() {
    logger.info('branding: Initializing whitelabel information');
    const storedWhitelabel = localStorage.getItem('whitelabel');
    if (storedWhitelabel) {
      const parsedWhitelabelInformation = JSON.parse(storedWhitelabel);
      if (Object.keys(parsedWhitelabelInformation).length === 0) return;
      this.whitelabel = parsedWhitelabelInformation;
      return;
    }

    logger.info(
      'branding: No whitelabel information present in local storage. Using information from local branding folder'
    );

    // handle whitelabel in web version (also mobile???)
    if (!window.desktop) return;

    const whitelabel = await window.desktop.branding.getWhitelabel();
    if (whitelabel && typeof whitelabel === 'object') {
      this.saveWhitelabelInformation(whitelabel);
    }
  }

  async _initAppIcon() {
    if (!window.desktop) return;
    const appIconBase64 = await window.desktop.branding.getAppIconBase64();
    if (!appIconBase64) return;
    this.app_icon = appIconBase64;
  }

  _initBrandingDB() {
    return new Promise((resolve) => {
      const request = window.indexedDB.open('commland/branding', 1);

      request.onsuccess = async (e: any) => {
        const db = e.target.result as IDBDatabase;
        this._db = db;
        if (db.objectStoreNames.contains(OBJECT_STORE)) {
          logger.debug('branding: Object store exists in DB');
          await this._initCompanyLogo();
          resolve(true);
        }
      };

      request.onupgradeneeded = async (e: any) => {
        logger.debug(
          'branding: Object store does not exist in DB. Creating object store.'
        );

        const db = e.target.result as IDBDatabase;
        db.createObjectStore(OBJECT_STORE, {
          autoIncrement: true
        });

        resolve(true);
      };
    });
  }
}

export default Branding;
