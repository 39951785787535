import type { KazooApplication } from './types';
import { KazooSDK } from '@commland/kazoo-js-sdk';
import logger from '../../utils/logger';
import { alphaApplications, betaApplications } from './defaults/applications';

const getIconURL = (appId: string) => {
  const accountId = KazooSDK.getAccountId();
  const authToken = KazooSDK.getAuthToken();
  const apiUrl = window.config.API_URL;
  const userId = KazooSDK.getUserId();

  return `${apiUrl}/accounts/${accountId}/users/${userId}/applications/commland_ui/${appId}/icon?auth_token=${authToken}`;
};

const getUserAppsList = async () => {
  const accountId = KazooSDK.getAccountId();
  const userId = KazooSDK.getUserId();

  try {
    const {
      data: { data }
    } = await KazooSDK.get(
      `/accounts/${accountId}/users/${userId}/applications/commland_ui`
    );

    return data.map((application: KazooApplication) => {
      let phase = 'gold';

      // Workaround while we find a way to automate in which state an application is using the api - DUPT-441
      // The api does support the phase tag, but we still don't know who or how the apps are gonna be installed for commland_ui app type
      const isBetaApp = betaApplications.includes(application.name);
      const isAlphaApp = alphaApplications.includes(application.name);
      if (isBetaApp) {
        phase = 'beta';
      } else if (isAlphaApp) {
        phase = 'alpha';
      }

      return {
        ...application,
        icon_url: getIconURL(application.id),
        phase
      };
    });
  } catch (error) {
    logger.error('There was an error retrieving comm.land apps', error);
    return [];
  }
};

export default getUserAppsList;
