import type { LibwebphoneConfiguration } from '../types/libwebphone';

export const getMobileSdk = () => window.commlandMobile?.Sdk;

export const getIsNativePlatform = () =>
  getMobileSdk()?.core?.isNativePlatform ?? false;

export const getPlatform = () => getMobileSdk()?.core?.platform;

export const getAppInfo = async () => getMobileSdk()?.app.getInfo();

export const getPushNotificationService = () =>
  getMobileSdk()?.pushNotifications;

export const addMobileSetupToLibwebphoneConfiguration = async (
  config: LibwebphoneConfiguration
): Promise<LibwebphoneConfiguration> => {
  const deviceToken = getPushNotificationService()?.getToken();
  const appInfoId = (await getAppInfo()).id;
  const platform = getPlatform();

  if (platform === 'ios') {
    config.userAgent.custom_parameters = {
      contact_uri: {
        'app-id': appInfoId,
        'pn-tok': deviceToken,
        'pn-type': 'apple'
      }
    };
  }

  config.userAgent.user_agent = {
    user_agent: 'CommlandMobile'
  };

  return config;
};
