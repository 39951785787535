import { KazooSDK } from '@commland/kazoo-js-sdk';
import { navigateToUrl } from 'single-spa';
import logger from '../utils/logger';

type EphemeralTokenResponse = {
  id: string;
  token: string;
  ua: { Realm: string; Username: string };
  exp: number;
};

class Auth {
  init() {
    logger.info('auth: started auth module');
    this._initEventBindings();
    window.commlandEvents.emit('commland.auth.started');

    if (!this.isUserAuthenticated()) {
      navigateToUrl('#/login');
      return;
    }
  }

  isUserAuthenticated() {
    const authToken = KazooSDK.getAuthToken();

    return !!authToken;
  }

  async getDeviceEphemeralToken(device_id: string) {
    const account = KazooSDK.getUserAccount();
    const { accountId } = account;
    const url = `/accounts/${accountId}/devices/${device_id}/ephemeral_tokens`;
    const data = {
      data: {
        claims: { secret: 'data' }
      }
    };

    logger.info('Fetching device ephemeral token');
    const response = await KazooSDK.put(url, data);

    const {
      error,
      status,
      data: { data: tokenData }
    } = response;

    if (status === 201 && !error) {
      return tokenData as EphemeralTokenResponse;
    } else {
      logger.error('auth: Error fetching ephemeral token', { status, error });
      return {} as EphemeralTokenResponse;
    }
  }

  async getEphemeralTokenInformation(token: string) {
    const account = KazooSDK.getUserAccount();
    const userId = KazooSDK.user.id;
    const { accountId } = account;
    const url = `/accounts/${accountId}/users/${userId}/ephemeral_tokens/${token}`;

    logger.info('Fetching ephemeral token information');
    const response = await KazooSDK.get(url);

    return response.data.data as EphemeralTokenResponse;
  }

  // this will need to be updated when we use the new commland-sdk
  async isTokenExpired() {
    return KazooSDK.isAuthTokenExpired();
  }

  async _onUserLogInEvent() {
    logger.info('auth: received user log in event');
    window.websockets.init(window.config.WEBSOCKET_URL);
    await window.commland.routing.refreshRouting();
    navigateToUrl('#/phone-workspace');
  }

  async _onUserLogOutEvent() {
    logger.info('auth: received user log out event');

    this._cleanUpSDKUserInformation();
    this._cleanUpLocalStorage();
    // TODO: research: clean up libwebphone?
    // TODO: research: clean up events?
    window.libwebphone.stop();
    window.websockets.close();
    window.commland.notifications.clearAll();
    window.commland.routing._unregisterUIShell();
    window.commland.routing.removeUserApplications();
    navigateToUrl('#/login');
  }

  _cleanUpSDKUserInformation() {
    KazooSDK.signOut();
    KazooSDK.setCurrentAccount(null, null);
    KazooSDK.credentials = null;
  }

  _cleanUpLocalStorage() {
    const localStorageKeys = [
      'webphone_device_id',
      'authToken',
      'user',
      'user_id',
      'account_id',
      'credentials',
      'user_account_name',
      'v1_auth',
      'v1_devices',
      'v1_calls',
      'v1_conferences',
      'v1_settings',
      'v1_voicemails',
      'v1_faxes'
    ];

    localStorageKeys.forEach((k) => window.localStorage.removeItem(k));
  }

  _initEventBindings() {
    window.commlandEvents.on('commland.app.login', () =>
      this._onUserLogInEvent()
    );

    window.commlandEvents.on('commland.app.logout', () =>
      this._onUserLogOutEvent()
    );
  }
}

export default Auth;
