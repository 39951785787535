/**
 * Notes:
 * - we are repeating the localStorage flag (commland:show-hidden-features)
 *    to avoid issues with scoping this.
 */
class HiddenFeatures {
  shouldShowHiddenFeatures: boolean;
  _stringToBoolean: Record<string, boolean> = {
    true: true,
    false: false
  };

  constructor() {
    window.showHiddenFeatures = this._showHiddenFeatures;
    window.hideFeatures = this._hideFeatures;
  }

  init() {
    this.shouldShowHiddenFeatures = this._getShouldShowHiddenFeatures();
  }

  _getShouldShowHiddenFeatures() {
    const savedShowHiddenFeaturesString =
      localStorage.getItem('commland:show-hidden-features') ?? 'false';

    return this._stringToBoolean[savedShowHiddenFeaturesString];
  }

  _showHiddenFeatures() {
    window.localStorage.setItem('commland:show-hidden-features', 'true');
    location.reload();
  }

  _hideFeatures() {
    window.localStorage.setItem('commland:show-hidden-features', 'false');
    location.reload();
  }
}

export default HiddenFeatures;
